h3.mainDescriptor {
  margin-top: 25px;
}

.pastClients {
  display: flex;
  justify-content: center;

  > div {
    padding-right: 50px;
  }

  @media only screen and (max-width: 767px) {
    display: block;

    > div {
      padding-right: 0;
    }
  }

  img {
    height: 62px;
    width: auto;
    margin-bottom: 20px;
  }
}

.oneCapitalLogo {
  height: 62px;
  width: auto;
  margin-bottom: 20px;
}

.imageAndTextContainer {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 1em;
  max-width: 1150px;
  text-align: center;

  @media only screen and (max-width: 767px) {
    display: block;
  }

  .imageContainer {
    width: 33%;
    margin: 20px auto;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }

  .text {
    font-size: 24px;
    // margin-left: 50px;
    width: 66%;
    padding-top: 34px;

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.serviceCardContainer {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 50px;
  max-width: 1150px;
  text-align: center;

  @media only screen and (max-width: 767px) {
    display: block;
    padding: 0;
  }

  > div {
    width: 33%;

    @media only screen and (min-width: 768px) {
      padding-left: 1em;
      padding-right: 1em;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .card {
    padding: 2em;
    height: 100%;
    flex-direction: column;
    // justify-content: center;
    align-items: inherit;
    flex-wrap: nowrap;
    margin-bottom: 1em;

    h2 {
      font-size: 24px !important;
      font-weight: 700;
      padding: 20px;
    }

    p {
      font-size: 16px;
    }
  }
}

.chooseUsCard {
  height: 100%;
}

.cardCol {
  padding: 1em;

  > div {
    padding: 1em;
  }

  h2 {
    font-size: 24px !important;
    font-weight: 700;
    padding: 20px;
  }
}

.typography2 {
  font-size: 24px;
}
