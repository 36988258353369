hr{
  border: none;
    height: 2px;
    color: black; /* old IE */
    background-color: black; /* Modern Browsers */
    opacity: 10%;
}


.social-icons {
  text-decoration: inherit;
  color:black;
  margin-right: 10px;
}
.social-icons:hover {
  color:darkgrey;
}

.legal-links {
  color: black;
  text-decoration: none;
}

.legal-links:hover {
  text-decoration: underline;;
}

.link-no-style {
  text-decoration: none;
  color: black;
}

.fade-in-text {
  animation: fadeIn linear 5s;
  -webkit-animation: fadeIn linear 5s;
  -moz-animation: fadeIn linear 5s;
  -o-animation: fadeIn linear 5s;
  -ms-animation: fadeIn linear 5s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@media (max-height: 90rem) {
  .home-banner  {
    align-items: center;
    justify-content: center;
    height: 550px;
    display: flex;
  }
}
@media (max-height: 40rem) {
  .home-banner  {
    align-items: center;
    justify-content: center;
    height: 300px;
    display: flex;
  }
}

.navbar {
  background: rgba(0,0,0,0.8);
  margin-bottom: 1em;
}

.black-btns{
  color:white !important;
  background-color: black !important;
  border-color: black !important;
}

.white-btns{
  color:black !important;
  background-color: white !important;
  border-color: white !important;
}

h1 {
  font-size: 64px !important;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    font-size: 24px!important;
  }
}

h2 {
  font-size: 36px !important;
}

h3 {
  font-size: 28px !important;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    font-size: 16px !important;
  }
}
