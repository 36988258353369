.headerContainer {
  padding: 50px 0 100px;

  @media only screen and (max-width: 767px) {
    padding: 20px 0 0;
  }

  img {
    width: 200px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      width: auto;
    }
  }
}
