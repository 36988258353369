.logo {
  img {
    width: 400px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      width: auto;
    }
  }
}
